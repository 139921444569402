<template>
  <div class="contentValidation">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Semaine</span>
          </div>

          <b-form-select
            class="b-form-select-new-style"
            v-model="filterSemaine"
            :options="ComputedListWeek"
            @change="handleChange"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel ">statut</span>
          </div>

          <b-form-select
            class="b-form-select-new-style mr-2"
            v-model="filterStatu"
            :options="validationList"
            value-field="value"
            text-field="text"
            @change="handleChange"
          ></b-form-select>
        </div>
        <!-- <div class="box-label-champ mr-2">
          <div class="label-box-style  w-46-px ">
            <span class="title-tabel">Project</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select w-63-px"
            v-model="filterProjectType"
            :options="[{ value: null, text: 'tous' }, ...project_type]"
            value-field="value"
            text-field="text"
            @change="handleChange"
          ></b-form-select>
        </div> -->
        <div v-if="getChargementFactureRegie" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <b-button
            size="sm"
            variant="light"
            class="button-default-style ml-2"
            @click="exportFiles"
            >Export</b-button
          >
        </div>
      </div>
    </div>
    <div class="body-box-rapport">
      <palatte-color />
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style customTableGestionRegie table-header"
          :items="computedRegieFacture"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:head(details)>
            <button
              class="btn  btn-anomalie"
              :class="{
                desactive: ShowALL == false,
                active: ShowALL == true
              }"
              @click="ShowALL ? hideAllDetails() : showAllDetails()"
            >
              <font-awesome-icon icon="info" />
            </button>
          </template>

          <template v-slot:cell(details)="data">
            <button
              class="btn"
              :class="{
                desactive: data.detailsShowing == false,
                active: data.detailsShowing == true
              }"
              @click="data.toggleDetails"
            >
              <font-awesome-icon icon="info" />
            </button>
          </template>
          <template v-slot:cell(regie_name)="data">
            {{ data.item.regie_name }}
          </template>
          <template v-slot:cell(coeff_101_deroule)="data">
            {{ data.item.coeff_101_deroule }} €
          </template>
          <template v-slot:cell(coeff_101_souffle)="data">
            {{ data.item.coeff_101_souffle }} €
          </template>
          <template v-slot:cell(coeff_101_rampant)="data">
            {{ data.item.coeff_101_rampant }} €
          </template>
          <template v-slot:cell(coeff_102_murs)="data">
            {{ data.item.coeff_102_murs }} €
          </template>
          <template v-slot:cell(coeff_102_murs_iti)="data">
            {{ data.item.coeff_102_murs_iti }} €
          </template>
          <template v-slot:cell(coeff_102_murs_ite)="data">
            {{ data.item.coeff_102_murs_ite }} €
          </template>
          <template v-slot:cell(coeff_102_pignons)="data">
            {{ data.item.coeff_102_pignons }} €
          </template>
          <template v-slot:cell(coeff_103_vide_sanitaire)="data">
            {{ data.item.coeff_103_vide_sanitaire }} €
          </template>
          <template v-slot:cell(coeff_103_plafonds)="data">
            {{ data.item.coeff_103_plafonds }} €
          </template>
          <template v-slot:cell(total_surface_101)="data">
            {{ data.item.total_surface_101 }} m²
          </template>
          <template v-slot:cell(total_surface_102)="data">
            {{ data.item.total_surface_102 }} m²
          </template>
          <template v-slot:cell(total_surface_103)="data">
            {{ data.item.total_surface_103 }} m²
          </template>
          <template v-slot:cell(montant_101)="data">
            {{ data.item.montant_101 }} €
          </template>
          <template v-slot:cell(montant_102)="data">
            {{ data.item.montant_102 }} €
          </template>
          <template v-slot:cell(montant_103)="data">
            {{ data.item.montant_103 }} €
          </template>
          <template v-slot:cell(pay_previsite)="data">
            <span v-if="data.item.pay_previsite == true">
              <font-awesome-icon icon="check-circle" class="statu" />
            </span>
            <span v-else>
              <font-awesome-icon :icon="['far', 'circle']" class="statu" />
            </span>
          </template>
          <template v-slot:cell(total)="data">
            {{ data.item.total }} €
          </template>
          <template v-slot:cell(total_tasks)="data">
            {{ data.item.total_tasks }}
          </template>
          <template v-slot:cell(statut)="data">
            {{ data.item.statue | statutFormat }}
          </template>
          <template v-slot:cell(semaine)="data">
            {{ data.item.semaine }}
          </template>
          <template v-slot:cell(commentaire)="data">
            {{ data.item.commentaire }}
          </template>
          <template v-slot:cell(payed_at)="data">
            {{ data.item.payed_at }}
          </template>
          <template v-slot:cell(download)="data">
            <b-button
              size="sm"
              class="iconDownload"
              title="Appel  à facturation"
              @click.prevent.stop="
                download({ data: data.item, project_type: null })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template v-slot:cell(download_iso)="data">
            <b-button
              v-if="data.item.tasks.length > 0"
              size="sm"
              class="iconDownload"
              title="Appel à facturation iso"
              @click.prevent.stop="
                download({ data: data.item, project_type: 'ISO' })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template v-slot:cell(download_th)="data">
            <b-button
              v-if="data.item.th_tasks.length > 0"
              size="sm"
              class="iconDownload"
              title="Appel à facturation th"
              @click.prevent.stop="
                download({ data: data.item, project_type: 'TH' })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template v-slot:cell(upload)="data">
            <div
              class="container"
              @drop="handleFileDrop($event, data.item)"
              v-if="
                data.item.statue !== 'paye' &&
                  data.item.statue !== 'encore de paiement'
              "
            >
              <div class="file-wrapper" title="Upload facture">
                <input
                  type="file"
                  name="file-input"
                  accept="application/pdf,application/vnd.ms-excel"
                  @change="handleFileInput($event, data.item)"
                />
                <div class="upload_label" v-if="!data.item.file">
                  <font-awesome-icon icon="cloud-upload-alt" />
                </div>
                <div class="upload_label" v-if="data.item.file">
                  <font-awesome-icon
                    icon="file-pdf"
                    :pulse="getChargementUploadFactureRegie"
                  />
                </div>
              </div>
            </div>
          </template>
          <template #row-details="data">
            <b-card class="cardDetailsFactureRegie" v-if="data.item.tasks">
              <p v-if="data.item.tasks.length > 0">
                <strong>Liste de projets </strong>
              </p>
              <div
                class="body-task mb-2"
                v-for="task in data.item.tasks"
                :key="task.id"
              >
                <span class="span_num"
                  ><b> Numero dossier : </b>{{ task.numero_dossier }}</span
                >
                <span><b> Nom : </b>{{ task.nom }}</span>
                <span><b> Prenom : </b>{{ task.prenom }}</span>
                <span><b> Surface 101 : </b>{{ task.surface_101 }} m²</span>
                <span><b> Surface 102 : </b>{{ task.surface_102 }} m²</span>
                <span><b> Surface 103 : </b>{{ task.surface_103 }} m²</span>
                <span><b> Total surface : </b>{{ task.total_surface }} m²</span>
              </div>
              <p v-if="data.item.th_tasks.length > 0">
                <strong>Liste de projets TH</strong>
              </p>
              <div
                class="body-task mb-2"
                v-for="task_th in data.item.th_tasks"
                :key="task_th.id"
              >
                <span class="span_num"
                  ><b> Numero dossier : </b>{{ task_th.numero_dossier }}</span
                >
                <span><b> Nom : </b>{{ task_th.nom }}</span>
                <span><b> Prenom : </b>{{ task_th.prenom }}</span>
                <span
                  ><b> Montant total TH: </b
                  >{{ task_th.montant_total_ht }} m²</span
                >
              </div>
            </b-card>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowFactureRegie"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      filterProjectType: 'ISO',
      project_type: [
        { value: 'ISO', text: 'ISO' },
        { value: 'TH', text: 'TH' }
      ],
      filterStatu: null,
      ShowALL: false,
      filteryear: null,
      filterSemaine: null,
      ListYear: [],
      ListWeek: [],
      page: 1,
      perPage: 10,
      fields: [
        {
          key: 'details',
          label: '',
          thClass: 'th-color',
          tdClass: 'color'
        },
        {
          key: 'regie_name',
          label: 'Nom',
          thClass: 'th-name-regie',
          tdClass: 'td-name-regie'
        },
        { key: 'statut', label: 'Statut' },
        { key: 'semaine', label: 'Semaine' },
        { key: 'coeff_101_deroule', label: '101 CP Déroulé' },
        { key: 'coeff_101_souffle', label: '101 CP SOUFLEE' },
        { key: 'coeff_101_rampant', label: '101 Rampant' },
        { key: 'coeff_102_murs', label: '102 Murs' },
        { key: 'coeff_102_murs_iti', label: 'ITI' },
        { key: 'coeff_102_murs_ite', label: 'ITE' },
        { key: 'coeff_102_pignons', label: '102 PIGNONS' },
        { key: 'coeff_103_vide_sanitaire', label: '103 vide sanitaire' },
        { key: 'coeff_103_plafonds', label: '103 Plafonds' },
        { key: 'total_surface_101', label: 'Total surface 101' },
        { key: 'total_surface_102', label: 'Total surface 102' },
        { key: 'total_surface_103', label: 'Total surface 103' },
        { key: 'montant_101', label: 'Montant 101' },
        { key: 'montant_102', label: 'Montant 102' },
        { key: 'montant_103', label: 'Montant 103' },
        {
          key: 'pay_previsite',
          label: 'Payer previsite',
          thClass: 'thpay-previsite'
        },
        { key: 'total', label: 'Total' },
        { key: 'TH_total', label: 'total TH' },
        { key: 'total_tasks', label: 'Total dossier' },
        { key: 'commentaire', label: 'Commentaire' },
        { key: 'payed_at', label: 'Date de paiement' },
        {
          key: 'download_iso',
          label: 'Appel à facturation ISO'
        },
        {
          key: 'download_th',
          label: 'Appel à facturation TH'
        },
        {
          key: 'download',
          label: 'Appel facture',
          thClass: 'thvalidationR',
          tdClass: 'tdvalidationR'
        },
        {
          key: 'upload',
          label: 'Upload facture',
          thClass: 'thpaiementR',
          tdClass: 'tdpaiementR'
        }
      ],
      validationList: [
        { value: null, text: 'tous' },
        { value: 'en attend', text: 'En cours de validation' },
        { value: 'refuse', text: 'Réfusé' },
        { value: 'valid', text: 'appel à facturation valide' },
        {
          value: 'encore de verification',
          text: 'En cours de vérification'
        },
        { value: 'facture valid', text: ' Facture validé' },
        { value: 'facture refuse', text: 'Facture refusé' },

        { value: 'encore de paiement', text: 'En cours de paiement' },
        { value: 'paye', text: 'Payé' }
      ],
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getFactureFiltredForRegie',
      'validationFactureRegie',
      'downloadFactureRegiePDF',
      'downloadFactureRegieXML',
      'uploadFactureRegie'
    ]),
    handleFileDrop(e, item) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach(f => {
        item.file = f;
      });
      this.uploadFactureRegie(item);
    },
    async handleFileInput(e, item) {
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      [...files].forEach(f => {
        item.file = f;
      });
      const responce = await this.uploadFactureRegie(item);
      if (responce) {
        this.$alert('', 'facture uploadé', 'success');
      } else {
        this.$alert(this.getErrorUploadFacture, '', 'error');
      }
    },
    showAllDetails() {
      this.computedRegieFacture.forEach(item => {
        this.$set(item, '_showDetails', true);
      });
      this.ShowALL = !this.ShowALL;
    },
    hideAllDetails() {
      this.computedRegieFacture.forEach(item => {
        this.$set(item, '_showDetails', false);
      });
      this.ShowALL = !this.ShowALL;
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.statue == 'refuse') return 'ligneRefuse';
      else if (item.statue == 'valid') return 'ligneAccepted';
      else if (item.statue == 'encore de verification')
        return 'ligneVerification';
      else if (item.statue == 'facture valid') return 'ligneValidF';
      else if (item.statue == 'facture refuse') return 'ligneRefuseF';
      else if (item.statue == 'encore de paiement') return 'ligneEnAttend';
      else if (item.statue == 'paye') return 'lignePaye';
      else return 'ligneEnCourDeValidation';
    },
    handleChange() {
      this.page = 1;
      sessionStorage.setItem('page-gestion-facture-regie', this.page);
      localStorage.setItem(
        'gestion-facture-regie-Filters',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterStatu: this.filterStatu
        })
      );
      this.getFactureFiltredForRegie({
        semaine: this.filterSemaine,
        year: this.filteryear,
        statu: this.filterStatu,
        page: this.page,
        per_page: this.perPage,
        filterProjectType: this.filterProjectType
      });
    },
    handleChangeYear() {
      var Filters = JSON.parse(
        localStorage.getItem('gestion-facture-regie-Filters')
      );
      Filters.filteryear = this.filteryear;
      localStorage.setItem(
        'gestion-facture-regie-Filters',
        JSON.stringify(Filters)
      );
      this.page = 1;
      sessionStorage.setItem('page-gestion-facture-regie', this.page);
      var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
      this.getFactureFiltredForRegie({
        semaine: this.filterSemaine,
        year: this.filteryear,
        statu: this.filterStatu,
        page: this.page,
        per_page: this.perPage,
        filterProjectType: this.filterProjectType
      });
    },
    pagination(paginate) {
      this.page = paginate;
      sessionStorage.setItem('page-gestion-facture-regie', this.page);
      this.getFactureFiltredForRegie({
        semaine: this.filterSemaine,
        year: this.filteryear,
        statu: this.filterStatu,
        page: this.page,
        per_page: this.perPage,
        filterProjectType: this.filterProjectType
      });
      this.ShowALL = false;
    },
    changePerPage() {
      this.page = 1;
      sessionStorage.setItem('page-gestion-facture-regie', this.page);
      this.getFactureFiltredForRegie({
        semaine: this.filterSemaine,
        year: this.filteryear,
        statu: this.filterStatu,
        page: this.page,
        per_page: this.perPage,
        filterProjectType: this.filterProjectType
      });
      this.ShowALL = false;
    },
    exportFiles() {
      this.downloadFactureRegieXML({
        semaine: this.filterSemaine,
        year: this.filteryear,
        statu: this.filterStatu
      });
    },
    download(item) {
      this.downloadFactureRegiePDF({
        item: item.data,
        year: this.filteryear,
        project_type: item.project_type
      });
    }
  },
  computed: {
    ...mapGetters([
      'getChargementFactureRegie',
      'getTotalRowFactureRegie',
      'getFactureRegie',
      'getChargementUploadFactureRegie',
      'getErrorUploadFacture'
    ]),
    ComputedListWeek() {
      return [{ value: null, text: 'tous' }, ...this.ListWeek];
    },
    computedRegieFacture() {
      if (this.filterStatu != null) {
        return this.getFactureRegie.filter(
          row => row.statue == this.filterStatu
        );
      }
      return this.getFactureRegie;
    },
    computedFields() {
      if (this.filterProjectType == 'ISO') {
        return this.fields.filter(
          item => item.key != 'TH_total' && item.key != 'download_th'
        );
      } else if (this.filterProjectType == 'TH') {
        return this.fields.filter(
          item =>
            item.key == 'details' ||
            item.key == 'regie_name' ||
            item.key == 'semaine' ||
            item.key == 'total_tasks' ||
            item.key == 'commentaire' ||
            item.key == 'download' ||
            item.key == 'payed_at' ||
            item.key == 'statut' ||
            item.key == 'TH_total' ||
            item.key == 'download_th'
        );
      } else {
        return this.fields;
      }
    }
  },
  components: {
    palatteColor: () => import('../component/paletteColor')
  },
  filters: {
    statutFormat: value => {
      switch (value) {
        case 'en attend':
          return 'En cours de validation';
        case 'refuse':
          return 'Réfusé';
        case 'valid':
          return 'Validé lappel a facturation';
        case 'encore de verification':
          return 'En cours de vérification';
        case 'facture valid':
          return 'Validé la facture';
        case 'facture refuse':
          return 'Réfusé la facture';
        case 'encore de paiement':
          return 'En cours de paiement';
        case 'paye':
          return 'Payé';
        default:
          return value;
      }
    }
  },
  mounted() {
    var courantYear = moment().year();
    if (localStorage.getItem('gestion-facture-regie-Filters')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('gestion-facture-regie-Filters')
      ).filteryear;
      this.filterSemaine = JSON.parse(
        localStorage.getItem('gestion-facture-regie-Filters')
      ).filterSemaine;
      this.filterStatu = JSON.parse(
        localStorage.getItem('gestion-facture-regie-Filters')
      ).filterStatu;
    } else {
      this.filteryear = courantYear;
      this.filterSemaine = 's' + moment().isoWeek();
      localStorage.setItem(
        'gestion-facture-regie-Filters',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterStatu: this.filterStatu
        })
      );
    }
    if (sessionStorage.getItem('page-gestion-facture-regie')) {
      this.page = sessionStorage.getItem('page-gestion-facture-regie');
    } else {
      sessionStorage.setItem('page-gestion-facture-regie', this.page);
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
    for (var week = 1; week <= totalWeek; week++) {
      this.ListWeek.push({ value: 's' + week, text: week });
    }
    this.getFactureFiltredForRegie({
      semaine: this.filterSemaine,
      year: this.filteryear,
      statu: this.filterStatu,
      page: this.page,
      per_page: this.perPage,
      filterProjectType: this.filterProjectType
    });
  }
};
</script>
<style lang="scss" scoped>
.contentValidation {
  padding: 10px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .cardDetailsFactureRegie {
    position: relative;
    background-color: #ededed !important;
    color: #858283;
    .card-body {
      width: 1143px;
    }
    p {
      color: #2dabe2;
      margin-bottom: 0px;
      font-size: 14px;
      width: 134px;
    }
    .body-task {
      display: flex;
      margin-left: 26px;
      span {
        width: 12%;
        text-align: start;
      }
      .span_num {
        width: 20%;
      }
    }
  }
  .customTableGestionRegie {
    max-height: calc(100vh - 189px) !important;
    height: calc(100vh - 189px) !important;
    margin-bottom: 0px;
  }
  .body-box-rapport {
    height: calc(100vh - 105px);
  }
  .container {
    height: 28px;
    width: 28px;
    padding: 2px;
    .file-wrapper {
      text-align: center;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      position: relative;
      overflow: hidden;
      border: 1px solid #6c757d;
      border-radius: 50%;
      color: #6c757d;
      background-color: transparent;
      &:hover {
        background-color: #6c757d;
        border-color: white;
        color: white;
      }
      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 5;
      }
      .display {
        display: none;
      }
      .upload_label {
        font-size: 14px;
        p {
          font-size: 9px;
        }
      }
    }
  }
  .statu {
    font-weight: 600;
    color: #479a57;
    font-size: 14px;
  }
}
</style>
